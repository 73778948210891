import { useState, Fragment, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import clsx from "clsx";
import { Popover, Transition, Disclosure, Dialog } from "@headlessui/react";

// components
import { Container } from "@/components/Container";
import { Select, Space } from "antd";

// icons
import { Logo } from "@/components/Logo";
import Basket from "@/public/icons/basket.svg";
import Login from "@/public/icons/login.svg";
import DragIcon from "@/public/icons/drag-icon.svg";
import Close from "@/public/icons/close.svg";

import Arrow from "@/public/icons/line-arrow.svg";

// stores
import { handleShowLoginModal, handleOpenCartPanel } from "@/stores/modals";
import { handleLogout } from "@/stores/user";
import { handleGetCart } from "@/stores/cart";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "@/stores/store";

import { PRODUCTS, DESIGNS } from "@/constants/wallart-categories";

import { publicRoutes } from "@/constants/routers";

import { useTranslation } from "next-i18next";

export const Header = ({ isFixed, classNames }: any) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isUserLogged = useTypedSelector(({ user }) => user.isAdmin);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const cartItems = useTypedSelector(({ cart }) => cart.cart);
  const router = useRouter();
  const [locale, setLocale] = useState(router.locale);
  const { t } = useTranslation("navigations");

  useEffect(() => {
    dispatch(handleGetCart());
  }, []);

  const openCartPanel = () => {
    dispatch(handleOpenCartPanel());
  };

  const ControllButton = ({ children, state }) => {
    return (
      <button
        type="button"
        className="w-[20px] h-[20px] text-text"
        onClick={() => setMobileMenuOpen(state)}
      >
        {children}
      </button>
    );
  };

  const handleChangeLocale = locale => {
    setLocale(locale);

    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      undefined,
      { locale }
    );
  };

  return (
    <header
      className={clsx(
        "flex  px-[1.5rem] lg:px-[2rem] py-[1.5rem] w-full bg-primary z-50",
        isFixed ? "sticky top-0" : "relative",
        classNames
      )}
    >
      <Container className="px-0">
        <div className="flex items-center">
          <nav className="flex mr-[2rem] xs:flex-1 gap-[2rem]">
            <div className="flex lg:hidden">
              <ControllButton state={true}>
                <DragIcon width={20} height={20} />
              </ControllButton>
            </div>
            <Popover.Group className="hidden lg:flex lg:items-center lg:gap-x-12">
              {isMenuOpen && (
                <div className="fixed top-[6.2rem] left-0 right-0 bottom-0 z-[-2] bg-text/[.7]"></div>
              )}

              <Popover>
                <Popover.Button className="flex items-center gap-x-1 text-caption text-text hover:text-link font-semibold whitespace-nowrap">
                  {t("header_link_1")}
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-0 top-[6.2rem] z-10 w-screen overflow-hidden bg-secondary">
                    <div className="py-[2rem] px-[2rem]">
                      <Container>
                        <div className="flex gap-[2rem]">
                          {PRODUCTS.map(
                            ({
                              name,
                              image,
                              description,
                              href,
                              translate_key,
                            }) => (
                              <div
                                key={name}
                                className="group w-[15%] group relative flex flex-col rounded-lg bg-primary overflow-hidden"
                              >
                                <div className="cards overflow-hidden flex transition relative aspect-square group-hover:blur-sm">
                                  <Image
                                    src={image}
                                    alt="terra"
                                    layout="fill"
                                    objectFit="contain"
                                    objectPosition="center"
                                    quality={100}
                                    className="rotate-[25deg] scale-[0.8]"
                                  />
                                </div>
                                <div className="flex-auto p-[1rem]">
                                  <Link
                                    href={href}
                                    className="flex items-center gap-[1rem] text-caption font-semibold text-text xl:text-caption"
                                  >
                                    {name}{" "}
                                    <Arrow
                                      width={10}
                                      className="ml-auto"
                                      fill="#283c8a"
                                    />
                                    <span className="absolute inset-0" />
                                  </Link>
                                  <p className="mt-1 text-text text-captionSmall 2xl:text-caption line-clamp-2">
                                    {t(translate_key)}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </Container>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </Popover.Group>

            <Popover.Group className="hidden lg:flex lg:items-center lg:gap-x-12">
              <Popover className="">
                <Popover.Button className="flex items-center gap-x-1 text-caption text-text hover:text-link font-semibold whitespace-nowrap">
                  {t("header_link_2")}
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-0 top-[6.2rem] z-10 w-screen overflow-hidden bg-secondary">
                    <div className="py-[2rem] px-[2rem]">
                      <Container>
                        <div className="flex gap-[2rem]">
                          {DESIGNS.map(
                            ({ title, links, translate_key }, rootIdx) => {
                              return (
                                <div key={rootIdx} className="w-[15%]">
                                  <h5 className="text-bodySmall font-semibold mb-[1rem] text-text">
                                    {t(translate_key)}
                                  </h5>

                                  {links.length > 0 && (
                                    <div>
                                      {links.map(
                                        (
                                          { title, link, translate_key },
                                          idx
                                        ) => {
                                          return (
                                            <Popover.Button
                                              as={Link}
                                              href={link}
                                              key={idx}
                                            >
                                              <span className="block text-captionSmall text-text mb-[1rem] hover:text-link">
                                                {t(translate_key)}
                                              </span>
                                            </Popover.Button>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </Container>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </Popover.Group>

            <div className="hidden lg:block">
              <Link
                href={publicRoutes.about}
                className="flex items-center gap-x-1 text-caption text-text hover:text-link font-semibold whitespace-nowrap"
              >
                {t("header_link_3")}
              </Link>
            </div>
            {/* <div className="hidden lg:block">
              <Link
                href={publicRoutes.gifts}
                className="flex items-center gap-x-1 text-caption text-text hover:text-link font-semibold whitespace-nowrap"
              >
                {t("header_link_4")}
              </Link>
            </div> */}

            {/* MOBILE VERSION */}
            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <Dialog.Panel className="fixed inset-y-0 left-[0] z-[999] w-full overflow-y-auto bg-primary px-6 py-6 sm:max-w-[40rem] sm:ring-1 sm:ring-gray-900/10 transition-all">
                <div className="flex items-center">
                  <div className="mr-[2rem]">
                    <Logo />
                  </div>

                  <div className="ml-auto text-[0px]">
                    <ControllButton state={false}>
                      <Close width={20} height={20} />
                    </ControllButton>
                  </div>
                </div>
                <div className="mt-6 flow-root">
                  <div className="flex flex-col gap-[1rem]">
                    <Disclosure as="div" className="bg-secondary">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="py-[2rem] px-[1rem] flex w-full items-center justify-between rounded-lg text-captionSmall font-semibold text-text hover:text-link">
                            {t("header_link_1")}
                          </Disclosure.Button>
                          <Disclosure.Panel className="border-t py-[2rem] flex flex-col gap-[2rem]">
                            {[...PRODUCTS].map(item => (
                              <Link
                                key={item.name}
                                href={item.href}
                                className="flex items-center  text-captionSmall gap-[1rem]"
                              >
                                <div className="w-[70px] h-[70px] relative">
                                  <Image
                                    src={item.image}
                                    alt={item.name}
                                    layout="fill"
                                    objectFit="contain"
                                    objectPosition="center"
                                    quality={100}
                                  />
                                </div>{" "}
                                {item.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure as="div" className="bg-secondary">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-[2rem] px-[1.5rem] text-captionSmall font-semibold text-text hover:text-link">
                            {t("header_link_2")}
                          </Disclosure.Button>
                          <Disclosure.Panel className="border-t py-[2rem] flex flex-col gap-[1rem] px-[1.5rem]">
                            {[...DESIGNS].map(item => (
                              <div
                                key={item.title}
                                className="flex text-left flex-col w-full items-start rounded-lg text-captionSmall"
                              >
                                <Disclosure as="div" className="w-full">
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg text-captionSmall font-semibold text-text hover:text-link">
                                        {t(item.translate_key)}
                                      </Disclosure.Button>

                                      <Disclosure.Panel className="mt-2">
                                        {item.links.length > 0 && (
                                          <div className="w-full pl-[1rem]">
                                            {item.links.map(
                                              (
                                                { title, link, translate_key },
                                                idx
                                              ) => {
                                                return (
                                                  <Link href={link} key={idx}>
                                                    <span className="block text-captionSmall p-[1rem] text-text hover:text-link">
                                                      {t(translate_key)}
                                                    </span>
                                                  </Link>
                                                );
                                              }
                                            )}
                                          </div>
                                        )}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </div>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                    <Disclosure as="div" className="bg-secondary">
                      <Link
                        href={publicRoutes.about}
                        className="flex w-full items-center justify-between rounded-lg py-[2rem] pl-[1.5rem] pr-[1.5rem] text-captionSmall font-semibold text-text hover:text-link"
                      >
                        {t("header_link_3")}
                      </Link>
                    </Disclosure>

                    {/* <Disclosure as="div" className="bg-secondary">
                      <Link
                        href={publicRoutes.gifts}
                        className="flex w-full items-center justify-between rounded-lg py-[2rem] pl-[1.5rem] pr-[1.5rem] text-captionSmall font-semibold text-text hover:text-link"
                      >
                        {t("header_link_4")}
                      </Link>
                    </Disclosure> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </nav>

          <div className="flex flex-shrink-0 justify-center lg:flex-1">
            <Logo />
          </div>

          <div className="flex flex-1 justify-end items-center">
            <Select
              defaultValue={locale}
              size="small"
              variant="borderless"
              dropdownStyle={{ width: 70 }}
              onChange={handleChangeLocale}
              options={[
                { value: "ua", label: "Ua" },
                { value: "en", label: "Eng" },
                { value: "pl", label: "Pl", disabled: true },
              ]}
              className={clsx("mr-[2rem]")}
            />

            {/* <Link
              href={{
                pathname: router.pathname,
                query: router.query,
              }}
              locale={locale}
              className="mr-[2rem] text-[1.6rem]"
            >
              <button>{locale}</button>
            </Link> */}

            {isUserLogged && (
              <button
                type="button"
                className="flex items-center text-text mr-2"
                onClick={() => dispatch(handleLogout())}
              >
                LOGOUT
              </button>
            )}

            {mobileMenuOpen && (
              <div className="fixed top-0 inset-0 z-10 bg-[rgba(0,0,0,0.5)]" />
            )}

            <button
              type="button"
              className="flex items-center justify-end relative"
              onClick={() => openCartPanel()}
            >
              <Basket width={25} fill="#000" />

              <span className="text-white ml-2 absolute top-[-2px] right-[-5px] bg-button w-[1.5rem] h-[1.5rem] rounded-[9999rem]">
                {cartItems.length}
              </span>
            </button>
          </div>
        </div>
      </Container>
    </header>
  );
};
